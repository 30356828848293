import { API_URL, LOCAL_STORAGE_INFO } from "@/const";
import axios from "axios";

export default {
    methods: {
        setStorage(key, value) {
            let data;
            var expire = new Date(); 
            // 10分キャッシュする
            expire.setMinutes(expire.getMinutes() + 10);
            data = {
                expire: expire,
                value: value
            };
            localStorage.setItem(key, JSON.stringify(data));
        },     
        getStorage(key) {
            let s = localStorage[key];
            if (s === undefined) {
                return undefined;
            }
            s = JSON.parse(s);
            if (new Date(s.expire)  > new Date()) {
                return s.value;
            } else {
                this.removeStorage(key);
                return undefined;
            }
        },
        removeStorage(key) {
            localStorage.removeItem(key);
        },
        removeAllSelectionLocalStorage() {
            localStorage.removeItem('generatorCollectSelection');
            localStorage.removeItem('generatorDisposalSelection');
            localStorage.removeItem('collectorDisposalCompanySelection');
            localStorage.removeItem('generatorCompanySelection');
            localStorage.removeItem('contractCollectCompanySelection');
            localStorage.removeItem('contractDisposalCompanySelection');
            localStorage.removeItem('disposalSiteSelection');
            localStorage.removeItem('companySelection');
            localStorage.removeItem('disposalGenerateSelectcompany');
            localStorage.removeItem('disposalCollectSelectcompany');
            localStorage.removeItem('generateCompanySelection');
            localStorage.removeItem('collectCompanySelectionOfEntrust');
            localStorage.removeItem('collect_company_of_entrust_create');
            localStorage.removeItem('disposal_company_of_entrust_create');
            // 事業場絞り込みデータのクリア
            localStorage.removeItem(LOCAL_STORAGE_INFO.USE_STORE_LIST);
            localStorage.removeItem(LOCAL_STORAGE_INFO.USE_STORE_LIST_CHECK);
            // 廃棄物種類絞り込みデータのクリア
            localStorage.removeItem(LOCAL_STORAGE_INFO.USE_JWNET_ITEM_TYPE);
            localStorage.removeItem(LOCAL_STORAGE_INFO.USE_JWNET_ITEM_TYPE_CHECK);
        },
        //運搬事業者選択肢取得API
        getGeneratorCollectSelection(url) {
            let selection = this.getStorage('generatorCollectSelection');
            var self = this;
            return new Promise(function (resolve, reject) {
                //キャッシュのデータがあるかどうかチェック
                if(selection !== undefined) {
                    resolve(selection)
                } else {
                    axios
                    .get(url)
                    .then((res) => {
                        let generatorCollectList = [];
                        if (url === API_URL.MANIFEST.GENERATOR_COLLECT_LIST) generatorCollectList = res.data.generatorCollectList.sort((a, b) => (a.name > b.name) ? 1 : -1);
                        if (url === API_URL.DISPOSER.COLLECT_COMPANY_SELECT) generatorCollectList = res.data.collectCompanyList.sort((a, b) => (a.name > b.name) ? 1 : -1);
                        self.setStorage('generatorCollectSelection', generatorCollectList)
                        resolve(generatorCollectList)
                    }).catch((err) => reject(err));
                }
            })
        },
        // 排出事業者選択肢取得API
        getGeneratorCompanySelection(url) {
            let selection = this.getStorage('generatorCompanySelection');
            var self = this;
            return new Promise(function (resolve, reject) {
                //キャッシュのデータがあるかどうかチェック
                if(selection !== undefined) {
                    resolve(selection)
                } else {
                    axios
                    .get(url)
                    .then((res) => {
                        const generatorCompanyList = res.data.generateCompanyList.sort((a, b) => (a.name > b.name) ? 1 : -1)
                        self.setStorage('generatorCompanySelection', generatorCompanyList)
                        resolve(generatorCompanyList)
                      }).catch((err) => reject(err));
                    }
                })
            },

        // 委託契約登録画面：処分事業者のsuggestion対応
        getDisposalCompanySelectionForEntrustCreate() {
            let selection = this.getStorage('disposal_company_of_entrust_create');
            var self = this;
            return new Promise(function (resolve, reject) {
                //キャッシュのデータがあるかどうかチェック
                if(selection !== undefined) {
                    resolve(selection)
                } else {
                    axios
                        .get(API_URL.ENTRUST.DISPOSAL_COMPANY)
                        .then((res) => {
                            const disposalCompanyList = res.data.disposalCompanyList.sort((a, b) => (a.name > b.name) ? 1 : -1)
                            self.setStorage('disposal_company_of_entrust_create', disposalCompanyList)
                            resolve(disposalCompanyList)
                        }).catch((err) => reject(err));
                }
            })
        },

        // 委託契約登録画面：運搬事業者のsuggestion対応
        getCollectCompanySelectionForEntrustCreate() {
            let selection = this.getStorage('collect_company_of_entrust_create');
            var self = this;
            return new Promise(function (resolve, reject) {
                //キャッシュのデータがあるかどうかチェック
                if(selection !== undefined) {
                    resolve(selection)
                } else {
                    axios
                        .get(API_URL.ENTRUST.COLLECT_COMPANY)
                        .then((res) => {
                            const collectCompanyList = res.data.collectCompanyList.sort((a, b) => (a.name > b.name) ? 1 : -1)
                            self.setStorage('collect_company_of_entrust_create', collectCompanyList)
                            resolve(collectCompanyList)
                        }).catch((err) => reject(err));
                }
            })
        },

        // 委託契約検索画面の運搬事業者選択肢取得
        getCollectCompanySelectionForEntrust(url) {
            let selection = this.getStorage('collectCompanySelectionOfEntrust');
            var self = this;
            return new Promise(function (resolve, reject) {
                //キャッシュのデータがあるかどうかチェック
                if(selection !== undefined) {
                    resolve(selection)
                } else {
                    axios
                        .get(url)
                        .then((res) => {
                            var generatorCollectList = [];
                            if (url == API_URL.MANIFEST.GENERATOR_COLLECT_LIST) generatorCollectList = res.data.generatorCollectList.sort((a, b) => (a.name > b.name) ? 1 : -1);
                            if (url == API_URL.DISPOSER.COLLECT_COMPANY_SELECT) generatorCollectList = res.data.collectCompanyList.sort((a, b) => (a.name > b.name) ? 1 : -1);
                                self.setStorage('collectCompanySelectionOfEntrust', generatorCollectList)
                            resolve(generatorCollectList)
                        }).catch((err) => reject(err));
                }
            })
        },

        // 委託契約 排出事業者選択肢取得API
        getGenerateCompanySelection(url) {
            let selection = this.getStorage('generateCompanySelection');
            var self = this;
            return new Promise(function (resolve, reject) {
                //キャッシュのデータがあるかどうかチェック
                if(selection !== undefined) {
                    resolve(selection)
                } else {
                    axios
                    .get(url)
                    .then((res) => {
                        const generateCompanyList = res.data.generateCompanyList.sort((a, b) => (a.name > b.name) ? 1 : -1)
                        self.setStorage('generateCompanySelection', generateCompanyList)
                        resolve(generateCompanyList)
                    }).catch((err) => reject(err));
                }
            })
        },
        //事業者選択肢取得API
        getCompanySelection() {
            let selection = this.getStorage('companySelection');
            var self = this;
            return new Promise(function (resolve, reject) {
                //キャッシュのデータがあるかどうかチェック
                if(selection !== undefined) {
                    resolve(selection)
                } else {
                    axios
                    .get(API_URL.ACCOUNT_MANAGEMENT.COMPANY_LIST)
                    .then((res) => {
                        const companyList = res.data.companyList.sort((a, b) => {
                            return a.name.localeCompare(b.name, 'ja');
                          });
                        self.setStorage('companySelection', companyList)
                        resolve(companyList)
                    }).catch((err) => reject(err));
                }
            })
        },
        // 契約に紐づく運搬事業者選択肢取得
        getContractCollectCompanySelection() {
            let selection = this.getStorage('contractCollectCompanySelection');
            var self = this;
            return new Promise(function (resolve, reject) {
                //キャッシュのデータがあるかどうかチェック
                if(selection !== undefined) {
                    resolve(selection)
                } else {
                    axios
                    .get(API_URL.CONTRACT.COLLECT_COMPANY)
                    .then((res) => {
                        const collectCompanyList = res.data.collectCompanyList.sort((a, b) => (a.name > b.name) ? 1 : -1)
                        self.setStorage('contractCollectCompanySelection', collectCompanyList)
                        resolve(collectCompanyList)
                    }).catch((err) => reject(err));
                }
            })
        },
        //処分事業者選択肢取得API
        getGeneratorDisposalSelection() {
            let selection = this.getStorage('generatorDisposalSelection');
            var self = this;
            return new Promise(function (resolve, reject) {
                //キャッシュのデータがあるかどうかチェック
                if(selection !== undefined) {
                    resolve(selection)
                } else {
                    axios
                    .get(API_URL.MANIFEST.GENERATOR_DISPOSAL_LIST)
                    .then((res) => {
                        const generatorDisposalList = res.data.generatorDisposalList.sort((a, b) => (a.name > b.name) ? 1 : -1)
                        self.setStorage('generatorDisposalSelection', generatorDisposalList)
                        resolve(generatorDisposalList)
                    }).catch((err) => reject(err));
                }
            })
        },
        // 運搬に紐づく処分事業者選択肢取得API
        getDisposalCompanySelectionForCollect() {
            let selection = this.getStorage('collectorDisposalCompanySelection');
            var self = this;
            return new Promise(function (resolve, reject) {
                //キャッシュのデータがあるかどうかチェック
                if(selection !== undefined) {
                    resolve(selection)
                } else {
                    axios
                    .get(API_URL.COLLECTOR.DISPOSAL_LIST)
                    .then((res) => {
                        const collectorDisposalCompanySelection = res.data.disposalCompanyList.sort((a, b) => (a.name > b.name) ? 1 : -1)
                        self.setStorage('collectorDisposalCompanySelection', collectorDisposalCompanySelection)
                        resolve(collectorDisposalCompanySelection)
                    }).catch((err) => reject(err));
                }
            })
        },
        //処分事業場選択肢取得API
        getDisposalSiteSelection() {
            let selection = this.getStorage('disposalSiteSelection');
            var self = this;
            return new Promise(function (resolve, reject) {
                //キャッシュのデータがあるかどうかチェック
                if(selection !== undefined) {
                    resolve(selection)
                } else {
                    axios
                    .get(API_URL.COMMON.DISPOSAL_SITE_LIST)
                    .then((res) => {
                        const disposalSiteList = res.data.disposalSiteList.sort((a, b) => (a.name > b.name) ? 1 : -1)
                        self.setStorage('disposalSiteSelection', disposalSiteList)
                        resolve(disposalSiteList)
                    }).catch((err) => reject(err));
                }
            })
        },
        // 契約に紐づく処分事業者選択肢取得
        getContractDisposalCompanySelection() {
            let selection = this.getStorage('contractDisposalCompanySelection');
            var self = this;
            return new Promise(function (resolve, reject) {
                //キャッシュのデータがあるかどうかチェック
                if(selection !== undefined) {
                    resolve(selection)
                } else {
                    axios
                    .get(API_URL.CONTRACT.DISPOSAL_COMPANY)
                    .then((res) => {
                        const disposalCompanyList = res.data.disposalCompanyList.sort((a, b) => (a.name > b.name) ? 1 : -1)
                        self.setStorage('contractDisposalCompanySelection', disposalCompanyList)
                        resolve(disposalCompanyList)
                    }).catch((err) => reject(err));
                }
            })
        },

        //処分事業者にひもづく排出事業者選択取得
        getDisposalGenerateSelection() {
            let selection = this.getStorage('disposalGenerateSelectcompany');
            var self = this;
            return new Promise(function (resolve, reject) {
                //キャッシュのデータがあるかどうかチェック
                if(selection !== undefined) {
                    resolve(selection)
                } else {
                    axios
                    .get(API_URL.MANIFEST.DISPOSAL_GENERATE_SELECTCOMPANY)
                    .then((res) => {
                        const generateCompanyList = res.data.generateCompanyList.sort((a,b) => (a.name > b.name) ? 1 : -1)
                        self.setStorage('disposalGenerateSelectcompany', generateCompanyList)
                        resolve(generateCompanyList)
                    }).catch((err) => reject(err));
                }
            })
        },

        //処分事業者にひもづく運搬事業者選択取得
        getDisposalCollectSelection() {
            let selection = this.getStorage('disposalCollectSelectcompany');
            var self = this;
            return new Promise(function (resolve, reject) {
                //キャッシュのデータがあるかどうかチェック
                if(selection !== undefined) {
                    resolve(selection)
                } else {
                    axios
                    .get(API_URL.MANIFEST.DISPOSAL_COLLECT_SELECTCOMPANY)
                    .then((res) => {
                        const collectCompanyList = res.data.collectCompanyList.sort((a,b) => (a.name > b.name) ? 1 : -1)
                        self.setStorage('disposalCollectSelectcompany', collectCompanyList)
                        resolve(collectCompanyList)
                    }).catch((err) => reject(err));
                }
            })
        },

        // 代理登録排出事業者選択肢取得
        getProxyGenerateCompanySelection() {
            let selection = this.getStorage('proxyGenerateCompanySelection');
            var self = this;
            return new Promise(function (resolve, reject) {
                //キャッシュのデータがあるかどうかチェック
                if(selection !== undefined) {
                    resolve(selection)
                } else {
                    axios
                    .get(API_URL.MANIFEST.PROXY_GENERATE_COMPANY)
                    .then((res) => {
                        const proxyGenerateCompanyList = res.data.proxyGenerateCompanyList.sort((a,b) => (a.name > b.name) ? 1 : -1)
                        self.setStorage('proxyGenerateCompanySelection', proxyGenerateCompanyList)
                        resolve(proxyGenerateCompanyList)
                    }).catch((err) => reject(err));
                }
            })
        },

        // 代理登録排出事業場選択肢取得
        getProxyGenerateStoreSelection() {
            let selection = this.getStorage('proxyGenerateStoreSelection');
            var self = this;
            return new Promise(function (resolve, reject) {
                //キャッシュのデータがあるかどうかチェック
                if(selection !== undefined) {
                    resolve(selection)
                } else {
                    axios
                    .get(API_URL.MANIFEST.PROXY_GENERATE_STORE)
                    .then((res) => {
                        const proxyGenerateStoreList = res.data.proxyGenerateStoreList.sort((a,b) => (a.name > b.name) ? 1 : -1)
                        self.setStorage('proxyGenerateStoreSelection', proxyGenerateStoreList)
                        resolve(proxyGenerateStoreList)
                    }).catch((err) => reject(err));
                }
            })
        },
    }
}
